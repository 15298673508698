/* Mixins */
.page-header {
  position: relative;
}
.page-header .tags {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-header .tags .ant-radio-button-wrapper {
  width: 200px;
  border-radius: 10px;
}
.page-header .tags .left-dots {
  margin-left: 10px;
  color: #2393cd;
  font-size: 30px;
}
.page-header .tags .arrow-right-icon {
  color: #2393cd;
}
.page-header .tags .right-dots {
  margin-right: 10px;
  color: #2393cd;
  font-size: 30px;
}